<template>
  <div :id="id" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-0">
          <div class="p-5 text-center">
            <XCircleIcon
              class="w-16 h-16 text-theme-24 mx-auto mt-3"
              v-if="!this.$slots.icon"
            />
            <slot name="icon"></slot>

            <div class="text-3xl mt-5">
              {{ title || defaultTitle }}
            </div>
            <div class="text-gray-600 mt-2 dark:text-gray-400">
              {{ message || defaultMessage }}
            </div>
          </div>
          <div
            class="px-5 pb-8 text-center flex items-center justify-center gap-2"
          >
            <button
              type="button"
              data-dismiss="modal"
              class="btn bg-theme-36 text-white w-24"
              :style="btnOkStyle"
              @click="doSubmit"
            >
              {{ i18n(`common.yes`) }}
            </button>
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300"
            >
              {{ i18n(`common.no`) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'confirm-modal'
    },
    title: {
      type: String
    },
    message: {
      type: String
    },
    item: {
      type: String
    },
    btnOkStyle: {
      type: String
    }
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    defaultTitle() {
      return this.i18n('common.deleteModal.areYouSure')
    },
    defaultMessage() {
      return this.i18n('common.deleteModal.wantToDelete') + this.item
    }
  },
  methods: {
    doSubmit() {
      this.$emit('submit')
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style></style>
