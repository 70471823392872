<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">{{ i18n('order.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Filter -->
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    ></TableFilter>
    <!-- END: HTML Table Filter -->
    <div class="intro-y box grid grid-cols-12 gap-6 mt-5">
      <div class="col-span-12 lg:col-span-12 p-5">
        <div
          class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
        >
          <button
            id="refreshBtn"
            type="button"
            @click="doResetFilter"
            class="btn bg-theme-36 text-white mb-2 flex items-center gap-2 transition duration-700 ease-in-out"
          >
            <RefreshCcwIcon
              class="h-5 w-5 refreshIcon transition duration-500"
            />
            <app-i18n code="common.refresh"></app-i18n>
          </button>
        </div>
        <!-- BEGIN: HTML Table Data -->
        <div ref="table" class="relative">
          <loading-spinner
            type="half-circle"
            :duration="1000"
            :size="40"
            color="#4469A8"
            :loading="loading"
          />
          <div class="overflow-x-auto">
            <!-- scrollbar-hidden -->
            <table
              id="ordersTable"
              ref="tabulator"
              class="table table-report sm:my-2"
            >
              <thead>
                <tr>
                  <th
                    v-for="col in columns"
                    :key="col.name"
                    class="text-center whitespace-nowrap"
                  >
                    <span>{{ i18n(col.label) }}</span>
                  </th>
                </tr>
              </thead>
              <tbody v-if="rows.length">
                <tr
                  v-for="(row, index) in rows"
                  :key="index"
                  class="intro-x"
                  :class="{ removedRow: row.isRemoved }"
                >
                  <td class="text-center">
                    <div class="font-medium whitespace-wrap text-theme-10">
                      {{ row.orderNo ? `#${row.orderNo}` : 'ـــ' }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row.chef, 'code') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row.client, 'code') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenter(row.captain, 'code') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap text-theme-10">
                      {{ presenterPrice(row.totalAmount) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      :class="{
                        'text-theme-10': row.paymentMethod == 'visa',
                        'text-theme-26': row.paymentMethod == 'wallet'
                      }"
                    >
                      {{
                        i18n(
                          `order.enumerators.paymentMethod.${row.paymentMethod}`
                        )
                      }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div
                      class="flex items-center justify-center"
                      :class="{
                        'text-gray-300': row.status == 'shadow',
                        'text-gray-600': row.status == 'inProgress',
                        'text-theme-24': row.status == 'cancelled',
                        'text-theme-10': row.status == 'closed'
                      }"
                    >
                      {{ i18n(`order.enumerators.status.${row.status}`) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterDate(row, 'createdAt') }}
                    </div>
                    <div class="font-small whitespace-nowrap mt-1">
                      {{ presenterTime(row, 'createdAt') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterDate(row, 'pickUpTime') }}
                    </div>
                    <div class="font-small whitespace-nowrap mt-1">
                      {{ presenterTime(row, 'pickUpTime') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterDate(row, 'deliveryTime') }}
                    </div>
                    <div class="font-small whitespace-nowrap mt-1">
                      {{ presenterTime(row, 'deliveryTime') }}
                    </div>
                  </td>
                  <td class="table-report__action min-w-64">
                    <div
                      class="flex justify-center items-center w-full text-theme-24 gap-3"
                    >
                      <router-link
                        class="text-theme-10 flex items-center gap-1"
                        :to="`/orders/${row.id}`"
                      >
                        <EyeIcon class="w-4 h-4" /> {{ i18n('common.view') }}
                      </router-link>
                      <a
                        v-if="
                          row.status === 'inProgress' ||
                            row.status === 'delivering'
                        "
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#cancel-order-modal"
                        @click="selectedId = row.id"
                        class="text-theme-24 flex items-center gap-1"
                      >
                        <XCircleIcon class="w-4 h-4" />
                        {{ i18n('common.cancel') }}
                      </a>
                      <a
                        v-if="row.status === 'delivering'"
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#close-order-modal"
                        @click="selectedId = row.id"
                        class="text-theme-36 font-medium flex items-center gap-1"
                      >
                        <LockIcon class="w-4 h-4" /> {{ i18n('common.close') }}
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <app-empty-page
              v-if="!rows.length && !loading"
              :label="i18n('empty.order')"
              label-class="text-lg font-medium"
              icon-size="4x"
              class="box w-full py-64"
            ></app-empty-page>
          </div>
          <Pagination
            v-if="rows.length"
            :page="currentPage"
            :pageSize="pagination.limit"
            :rowsNumber="pagination.total"
            :showSizeChanger="true"
            :hasNext="hasNext"
            :hasPrev="hasPrev"
            :loading="loading"
            :disabled="!rows.length"
            position="center"
            @next-page="doNextPage"
            @prev-change="doPrevPage"
            @size-change="doChangePaginationPageSize"
            @page-change="goToPage"
          ></Pagination>
        </div>
      </div>
    </div>

    <!-- BEGIN: Modals -->
    <CancelOrderModal :id="selectedId" />
    <ConfirmtModal
      id="close-order-modal"
      :title="i18n('order.deliverOrder.title')"
      :message="i18n('order.deliverOrder.message')"
      :item="i18n('order.item')"
      @submit="doClose({ id: selectedId })"
    >
      <template v-slot:icon>
        <LockIcon class="w-16 h-16 text-theme-36 mx-auto mt-3" />
      </template>
    </ConfirmtModal>
    <!-- END: Modals -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ConfirmtModal from '@/components/modals/confirm-modal.vue'
import CancelOrderModal from '@/views/order/modal/cancel-order-modal.vue'
import { paginationMixin } from '@/mixins/pagination-mixin'
import { presenterMixin } from '@/mixins/presenter-mixin'

export default {
  mixins: [paginationMixin, presenterMixin],
  components: {
    CancelOrderModal,
    ConfirmtModal
  },
  data() {
    return {
      // component Data
      selectedId: null,
      columns: [
        {
          name: 'orderNumber',
          field: 'orderNumber',
          label: 'order.fields.orderNumber',
          align: 'center'
        },
        {
          name: 'chefCode',
          field: 'chefCode',
          label: 'iam.fields.chefCode',
          align: 'center'
        },
        {
          name: 'clientCode',
          field: 'clientCode',
          label: 'iam.fields.clientCode',
          align: 'center'
        },
        {
          name: 'captainCode',
          field: 'captainCode',
          label: 'iam.fields.captainCode',
          align: 'center'
        },
        {
          name: 'totalAmount',
          field: 'totalAmount',
          label: 'order.fields.totalAmount',
          align: 'center'
        },
        {
          name: 'paymentMethod',
          field: 'paymentMethod',
          label: 'order.fields.paymentMethod',
          align: 'center'
        },
        {
          name: 'status',
          field: 'status',
          label: 'order.fields.status',
          align: 'center'
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'order.fields.createdAt',
          align: 'center',
          sortable: true,
          dataType: 'date'
        },
        {
          name: 'pickupTime',
          field: 'pickupTime',
          label: 'order.fields.pickUpTime',
          align: 'center'
        },
        {
          name: 'deliveryTime',
          field: 'deliveryTime',
          label: 'order.fields.deliveryTime',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'order/list/rows',
      hasNext: 'order/list/hasNext',
      hasPrev: 'order/list/hasPrev',
      pagination: 'order/list/pagination',
      listLoading: 'order/list/loading',
      saveLoading: 'order/form/saveLoading'
    }),
    loading() {
      return this.listLoading || this.saveLoading
    },
    currentPage() {
      return this.pagination.currentPage || 1
    },
    paymentMethodOptions() {
      return [
        {
          label: this.i18n('order.enumerators.paymentMethod.visa'),
          value: 'visa'
        },
        {
          label: this.i18n('order.enumerators.paymentMethod.wallet'),
          value: 'wallet'
        }
      ]
    },
    statusOptions() {
      return [
        {
          label: this.i18n('order.enumerators.status.delivering'),
          value: 'delivering'
        },
        {
          label: this.i18n('order.enumerators.status.inProgress'),
          value: 'inProgress'
        },
        {
          label: this.i18n('order.enumerators.status.cancelled'),
          value: 'cancelled'
        },
        {
          label: this.i18n('order.enumerators.status.closed'),
          value: 'closed'
        },
        {
          label: this.i18n('order.enumerators.status.shadow'),
          value: 'shadow'
        }
      ]
    },
    filterFields() {
      return [
        {
          label: this.i18n('order.fields.orderNumber'),
          value: 'orderNo',
          type: 'text'
        },
        {
          label: this.i18n('iam.fields.chefCode'),
          value: 'chef.code',
          type: 'text'
        },
        {
          label: this.i18n('iam.fields.clientCode'),
          value: 'client.code',
          type: 'text'
        },
        {
          label: this.i18n('iam.fields.captainCode'),
          value: 'captain.code',
          type: 'text'
        },
        {
          label: this.i18n('order.fields.totalAmount'),
          value: 'totalAmount',
          type: 'number-range'
        },
        {
          label: this.i18n('order.fields.paymentMethod'),
          value: 'paymentMethod',
          type: 'text'
        },
        {
          label: this.i18n('order.fields.status'),
          value: 'status',
          type: 'list',
          options: this.statusOptions
        },
        {
          label: this.i18n('order.fields.createdAt'),
          value: 'createdAt',
          type: 'date-range'
        },
        {
          label: this.i18n('order.fields.pickUpTime'),
          value: 'createdAt',
          type: 'date-range'
        },
        {
          label: this.i18n('order.fields.deliveryTime'),
          value: 'createdAt',
          type: 'date-range'
        }
      ]
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.orders')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.orders')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'order/list/doFetch',
      doFetchNextPage: 'order/list/doFetchNextPage',
      doFetchPreviousPage: 'order/list/doFetchPreviousPage',
      doChangePaginationCurrentPage: 'order/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'order/list/doChangePaginationPageSize',
      doCancel: 'order/form/doCancel',
      doClose: 'order/form/doClose'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
<style scoped>
#refreshBtn:hover .refreshIcon {
  transform: rotate(180deg);
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
