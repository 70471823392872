import { GenericModel } from '@/shared/model/generic-model'

export const presenterMixin = {
  methods: {
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterField(row, fieldName) {
      return GenericModel.presenterField(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterTime(row, fieldName) {
      return GenericModel.presenterTime(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterLocalizationWithNull(row, fieldName) {
      return GenericModel.presenterLocalizationWithNull(row, fieldName)
    },
    presenterIfLocalization(row, fieldName) {
      return GenericModel.presenterIfLocalization(row, fieldName)
    },
    presenterPrice(price) {
      return GenericModel.presenterPrice(price)
    },
    isThere(row, fieldName) {
      return row ? !!row[fieldName] : false
    }
  }
}
